body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ReactVirtualized__Grid.ReactVirtualized__List {
  overflow: auto !important;
}

.loader-container {
  width: 100%;
  max-height: calc(100vh - 350px);
  min-height: 90vh;
}
.loader-container > * {
  width: 100% !important;
  min-height: 90vh;
}
.card-wrapper {
  min-height: 200px;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 600px) {
  .card-wrapper {
    min-height: 400px;
    overflow: auto;
    max-height: 400px;
  }
  .card-wrapper .MuiCard-root {
    overflow: auto;
  }
}
.card-wrapper .MuiListItem-root {
  flex-wrap: wrap;
}

.directory-empty-card {
  max-width: 500px;
  margin: 50px auto 0;
  padding: 20px;
  text-align: center;
}

.directory-empty-card .MuiCardContent-root {
  padding-bottom: 16px;
}

.doctor .MuiSelect-selectMenu {
  white-space: pre-wrap;
}
